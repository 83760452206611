<template>
  <div class="notice">
    <div id="zone-head">
      <zone-header></zone-header>
      <div class="cms-header-content">
        <div class="cms-container">
          <div class="logo-image left">
            <img :src="logoUrl" class="w-100 h-100" alt="">
          </div>
          <div class="left"></div>
        </div>
      </div>
    </div>
    <div id="zone-head-placeholder"></div>
    <!--面包屑-->
    <div class="width_width child_bg_1">
      <div class="width_w clearfix">
        <div class="now_add left clearfix">
          <router-link class="now_a left" v-if="dataObj.newVersionZone =='1'"
                       :to="{name:'specializedNewZone',query:{'companyId':headCompanyId,'id':headId,'headLogo':headLogo}}">
            企业首页
          </router-link>
          <router-link class="now_a left" v-else-if="dataObj.newVersionZone =='2'"
                       :to="{name: headId == '6802' ?'specializedZoneYx':'specializedNewZoneTwo',query:{'companyId':headCompanyId,'id':headId,'headLogo':headLogo}}">
            企业首页
          </router-link>
          <router-link class="now_a left" v-else
                       :to="{name:'zone',query:{'companyId':headCompanyId,'id':headId,'headLogo':headLogo}}">
            企业首页
          </router-link>
          <img class="now_arrow left" src="../../assets/image/pc/jiantou.svg"/>
          <router-link class="now_a left"
                       :to="{name:'zonePurchase','query':{'companyId':headCompanyId,'id':headId,'headLogo':headLogo}}">
            {{ buyerTitle }}
          </router-link>
        </div>
      </div>
      <span class="child_name">{{ buyerTitle }}</span>
    </div>
    <div class="cms-container notice-info">
      <div class="notice-left left">
        <ul :class="{'tab_con':true,'clearfix':true,'qy_tab_con':company.companyId =='2114'}  ">
        <li v-for="(item,index) in noticeTypeList" :key="'notice_'+index">
            <a :class="{'tab_active':activeClass == index,'qy-bg-color':company.companyId =='2114' && activeClass == index}" @click="getItem(index,item,'activeClass')"
               class="tab_li"
               href="javascript:void(0)">{{ item.categoryTitle }}</a>
          </li>
        </ul>
        <div class="n_nar">
          <dl class="n_dl">
            <dt>公告搜索：</dt>
            <dd>
              <b-form-input id="menu_search" v-model="query.searchName" @input="menu_search" style="width:260px" size="lg"
                            placeholder="请输入公告/公司名称关键字"></b-form-input>
            </dd>
          </dl>
          <dl class="n_dl">
            <dt>业务类型：</dt>
            <dd>
              <a :class="{'dd_select':activeClass1 == index,'qy-bg-color':company.companyId =='2114' && activeClass1 == index}" @click="getItem(index,item,'activeClass1')"
                 :key="'activeClass-1-'+index"
                 href="javascript:void(0)"
                 v-for="(item,index) in businessType">{{ item.typeName }}</a>
            </dd>
          </dl>
          <dl class="n_dl">
            <dt>公告类型：</dt>
            <dd>
              <a :class="{'dd_select':activeClass4 == index,'qy-bg-color':company.companyId =='2114' && activeClass4 == index}" @click="getItem(index,item,'activeClass4')"
                 :key="'activeClass-4-'+index"
                 href="javascript:void(0)"
                 v-for="(item,index) in purchaseMode">{{ item.typeName }}</a>
            </dd>
          </dl>
          <dl class="n_dl">
            <dt>招标类型：</dt>
            <dd>
              <a :class="{'dd_select':activeClass3 == index,'qy-bg-color':company.companyId =='2114' && activeClass3 == index}" @click="getItem(index,item,'activeClass3')"
                 :key="'activeClass-3-'+index"
                 href="javascript:void(0)" v-for="(item,index) in formType">{{ item.typeName }}</a>
            </dd>
          </dl>
          <dl class="n_dl">
            <dt>发布时间：</dt>
            <dd>
              <a :class="{'dd_select':activeClass2 == index,'qy-bg-color':company.companyId =='2114' && activeClass2 == index}"
                 @click="getItem(index,item,'activeClass2')"
                 :key="'activeClass-2-'+index"
                 href="javascript:void(0)"
                 v-for="(item,index) in releaseTime">{{ item.scopeName }}</a>
            </dd>
          </dl>
        </div>
        <div class="tender_con">
          <el-empty  v-if="total ==0">
          </el-empty>
          <router-link :class="{'tender_a':true,'line_a':true,'clearfix':true,'qy_tender_a':company.companyId =='2114'}"
                       v-for="(item,index) in noticeList"
                       :key="'details-'+index"
                       :to="{name:'zonePurchaseDetails','query':{'contentId': item.contentId, 'type': item.type,'companyId':headCompanyId,'id':headId,'headLogo':headLogo,'formType':item.formType,vc:item.vc}}">
                <span class="left tender_span">
                    <i :class="{'qy-color':company.companyId =='2114'}" v-if="item.purchaseMode=='186'">【议价】</i>
                    <i :class="{'qy-color':company.companyId =='2114'}" v-else-if="item.purchaseMode==10 || item.purchaseMode== 210">【招标】</i>
                    <i :class="{'qy-color':company.companyId =='2114'}" v-else-if="item.purchaseMode=='201'">【竞争性谈判】</i>
                    <i :class="{'qy-color':company.companyId =='2114'}" v-else-if="item.purchaseMode=='202'">【竞争性磋商】</i>
                    <i :class="{'qy-color':company.companyId =='2114'}" v-else-if="item.purchaseMode=='204'">【询比价】</i>
                    <i :class="{'qy-color':company.companyId =='2114'}" v-else-if="item.purchaseMode=='203'">【比选采购】</i>
                    <i :class="{'qy-color':company.companyId =='2114'}" v-else-if="item.purchaseMode=='205'">【单一来源】</i>
                    <i :class="{'qy-color':company.companyId =='2114'}" v-else-if="item.purchaseMode=='206'">【竞价】</i>
                    <i :class="{'qy-color':company.companyId =='2114'}" v-else-if="item.purchaseMode=='207'">【供应商入围】</i>
                    <i :class="{'qy-color':company.companyId =='2114'}" v-else-if="item.purchaseMode=='30'">【单一来源】</i>
                    <i :class="{'qy-color':company.companyId =='2114'}" v-else-if="item.purchaseMode=='40'">【询价】</i>
                    <i :class="{'qy-color':company.companyId =='2114'}" v-else-if="item.purchaseMode=='50'">【竞价】</i>
                    <i :class="{'qy-color':company.companyId =='2114'}" v-else-if="item.purchaseMode=='60'">【询比价】</i>
                    <i :class="{'qy-color':company.companyId =='2114'}" v-else-if="item.purchaseMode=='70'">【竞争性谈判 | 磋商】</i>
                    <i :class="{'qy-color':company.companyId =='2114'}" v-else-if="item.purchaseMode=='80'">【单一来源】</i>
                    <i :class="{'qy-color':company.companyId =='2114'}" v-else-if="item.purchaseMode=='90'">【竞争性磋商】</i>
                    <i :class="{'qy-color':company.companyId =='2114'}" v-else-if="item.purchaseMode==null">【流标】</i>
                    {{ item.title }}</span>
            <span class="left tender_company">{{ item.companyName }}</span>
            <span class="right tender_time">{{ formatDate(item.createTime, 'YYYY/MM/DD') }}</span>
          </router-link>
        </div>
        <div :class="{'cms-box':true,'qy-cms-box' :company.companyId =='2114'}" style="margin-top: 10px">
          <el-pagination
              :page-size="pageSize"
              :total="total"
              @current-change="handleCurrentChange"
              background
              :pager-count="5"
              :current-page="currentPage"
              layout="prev, pager, next">
          </el-pagination>
        </div>
      </div>
    </div>
    <specialized-new-footer style="margin-top: 15px;" :configForm="configForm" :company="company"></specialized-new-footer>
    <technical-support></technical-support>
  </div>
</template>

<script>
import moment from "moment";
import $ from "jquery";
import ZoneHeader from "./zone-header";
import SpecializedNewFooter from "../specialized-new-zone/specialized-new-footer";
import TechnicalSupport from "../../components/commoon/component/technical-support";
import {getUserInfo} from "../../api/commoon/user";

export default {
  name: "purchase",
  components: {
    TechnicalSupport,
    SpecializedNewFooter,
    ZoneHeader,
  },
  data() {
    return {
      buyer: {},
      buyerList: [],
      noticeTypeList: [],
      noticeList: [],
      activeClass: 0,
      activeClass1: 0,
      activeClass2: 0,
      activeClass3: 0,
      activeClass4: 0,
      configForm: {},
      userLoginFlag:false,
      businessType: [
        {
          typeName: "全部",
          code: "",
        },
        {
          typeName: "货物",
          code: "10",
        },
        {
          typeName: "工程",
          code: "20",
        },
        {
          typeName: "服务",
          code: "30",
        }
      ],
      formType: [
        {
          typeName: "全部",
          code: "",
          type: "RECRUIT_BULLETIN"
        },
        {
          typeName: "公开",
          code: "10",
          type: "BID_BULLETIN"
        },
        {
          typeName: "邀请",
          code: "20",
          type: "BGG_INVITATION"
        },
      ],
      purchaseMode: [
        {
          typeName: "全部",
          code: "0",
        },
        {
          typeName: "招标",
          code: "10",
        },
        {
          typeName: "非招标",
          code: "-10",
        },
      ],
      releaseTime: [
        {
          scopeName: "全部",
          code: ""
        }, {
          scopeName: "今天",
          code: "10"
        }, {
          scopeName: "近三天",
          code: "20"
        }, {
          scopeName: "近五天",
          code: "30"
        }, {
          scopeName: "近一周",
          code: "40"
        }, {
          scopeName: "近半个月",
          code: "50"
        }, {
          scopeName: "本月",
          code: "60"
        },
      ],
      query: {},
      dataObj:{},
      total: 0,
      currentPage: 0,
      pageSize: 16,
      headCompanyId: "",
      headId: "",
      headLogo: "",
      buyerTitle:"采购公告",
      company:{}
    }
  },
  methods: {
    selectNoticeList: function (modelId, name, type) {
      const that = this;
      this.$http.get(this.base + "/category/list.do", {
        params: {
          modelId: modelId
        }
      }).then(function (data) {
        that[name] = data.data.result;
        that.query.type = data.data.result[that.activeClass].noticeType;
        if (that.query.type == 'BID_BULLETIN') {
          that.query.type = 'RECRUIT_BULLETIN';
        }
        that.query.purchaseMode = data.data.result[that.activeClass].purchaseMode;
        if (type == 2) {
          that.query.purchaseMode = "-10";
        } else if (type == 1) {
          that.query.purchaseMode = "10";
        } else {
          that.query.purchaseMode = "";
        }
        let id = that.$route.query.id;
        if (id != null && id != '') {
          that.query.companyName = id;
        }
        if (id == '2706' || id == '5246' || id == '5245') {
          that.query.companyName="";
        }
        that.getNoticeList(that.query)
      }).catch(function (error) {
        console.log(error);
      })
    },
    getNoticeList: function (query) {
      var that = this;
      this.$http.post(this.base + "/bulletin/getRecruit.do", {
        page: {
          current: that.currentPage,
          size: that.pageSize
        },
        cmsBulletinDTO: query
      }).then(function (data) {
        that.noticeList = data.data.result.records;
        that.total = data.data.result.total;
      }).catch(function (error) {
        console.log(error);
      })
    },
    getItem: function (index, item, activeClassName) {
      const that = this;
      this.currentPage = 0
      this[activeClassName] = index;
      /*公告类型*/
      if (activeClassName === "activeClass") {
        that.query.type = item.noticeType;
        if (item.noticeType == 'BID_BULLETIN') {
          that.query.type = 'RECRUIT_BULLETIN';
        }
      }
      /*业务类型*/
      if (activeClassName === "activeClass1") {
        that.query.businessType = item.code;
      }
      /*招标类型*/
      if (activeClassName === "activeClass3") {
        that.query.formType = item.code;
        if (item.code == '' && (that.query.type == 'BGG_INVITATION' || that.query.type == 'BID_BULLETIN')) {
          that.query.type = 'RECRUIT_BULLETIN ';
        }
      }
      /*发布时间*/
      if (activeClassName === "activeClass2") {
        that.query.releaseTime = item.code;
      }
      /*类型*/
      if (activeClassName === "activeClass4") {
        that.query.purchaseMode = item.code;
      }
      //切换时清空
      // that.query.searchName='';
      // $("#menu_search").val("");
      this.getNoticeList(that.query);
    },
    selectPromotionList: function (modelId, entityName, entityListName) {
      var that = this;
      that.$http.get(that.base + "/category/get.do", {
        params: {
          modelId: modelId
        }
      }).then(function (data) {
        that[entityName] = data.data.result;
        var categoryId = data.data.result.id;
        that.$http.post(that.base + "/promotion/page.do", {
          page: {
            size: 6,
            current: 0
          },
          cmsPromotionDTO: {
            categoryId: categoryId
          }
        }).then(function (data) {
          var data1 = data.data.result.records;
          for (var i = 0; i < data1.length; i++) {
            if (data1[i].imagesUrl != null && data1[i].imagesUrl != "") {
              data1[i].imagesUrl = JSON.parse(data1[i].imagesUrl);
            }
          }
          that[entityListName] = data1;
        }).catch(function (error) {
          console.log(error)
        })
      }).catch(function (error) {
        console.log(error)
      })
    },
    getConfigs: function (id) {
      let that = this;
      that.$http.get(that.base + "/content/getCall.do", {
        params: {
          id: id
        }
      }).then(function (res) {
        let data = res.data.result;
        if (data) {
          that.configForm = data;
          let userNames = that.configForm.userName.split("/");
          let phones = that.configForm.phone.split("/");
          that.configForm['userNames'] = userNames;
          that.configForm['phones'] = phones;
        }
      }).catch(function (err) {
        console.log(err);
      });
    },
    handleCurrentChange: function (val) {
      this.currentPage = val;
      this.getNoticeList(this.query);
    },
    formatDate: function (de, fmt) {
      return moment(de).format(fmt);
    },
    //键盘输入查询
    menu_search: function() {
      let val = this.query.searchName;
      if(val != ""){
        const pattern=/[`~!@#$^\-&*()=|{}':;',\\\[\]\.<>\/?~！@#￥……&*（）——|{}【】'；：""'。，、？\s]/g;
        val = val.replace(pattern,"");
      }
      this.query.searchName = val;
      this.getNoticeList(this.query);
    },
    arch: function () {
      this.query.searchName = $("#menu_search").val();
      this.getNoticeList(this.query);
    },
    getParameter: function (paraName) {
      var url = document.location.toString();
      if (url.lastIndexOf('#') == (url.length - 1)) {
        url = url.substring(0, url.lastIndexOf('#'));
      }
      var arrObj = url.split("?");

      if (arrObj.length > 1) {
        var arrPara = arrObj[1].split("&");
        var arr;

        for (var i = 0; i < arrPara.length; i++) {
          arr = arrPara[i].split("=");

          if (arr != null && arr[0] == paraName) {
            return arr[1];
          }
        }
        return "";
      } else {
        return "";
      }
    },
    getCategory: function (id) {
      var that = this;
      let cId = id + "";
      if (cId != null && cId != '' && cId.length > 0) {
        that.$http.get(this.base + "/promotion/get.do", {
          params: {
            'id': cId
          }
        }).then((data) => {
          let dataRes = data.data.result;
          that.dataObj=dataRes;
          that.logoUrl = JSON.parse(dataRes.logoUrl)[0].url;
        })
      }
    },
    //判断用户是否登入
    checkUser: function (uuid) {
      let that = this;
      getUserInfo(that.member).then(function (res) {
        that.userInfo = res.data.data;
        if (that.userInfo.isLogin =='1') {
          that["userLoginFlag"] = true;
        } else {
          that["userLoginFlag"] = false;
        }
      }).catch(function (err) {
        console.log(err);
      });

    },
    //从cookie获取值
    getCookie: function (name) {
      let that = this;
      let arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
      if (arr = document.cookie.match(reg)) {
        return (arr[2]);
      } else
        return null;
    },
    start() {
      // 将定时器名字赋值到变量中
      this.timer = setInterval(() => {
        this.checkUser(this.getCookie("uuid"));
        console.log("开始");
        if (this.userLoginFlag){
          this.end()
        }
      }, 5000);
    },
    end() {
      this.checkUser(this.getCookie("uuid"));
      clearInterval(this.timer);
      this.timer = null // 这里最好清除一下，回归默认值
      // 众所周知，定时器返回一个随机整数，用于表示定时器的编号，后面通过名字可以取消这个定时器的执行。
      console.log("结束！");
    },
    login: function () {
      if (this.userLoginFlag) {
        this.end();
      } else {
        this.start();
      }
      window.open(this.member + "/member/login/executeLogin.htm");
    },
    getCompany: function (promotionId, entityName) {
      let that = this;
      that.promotion = promotionId;
      that.$http.get(that.base + "/promotion/get.do", {
        params: {
          id: that.promotion
        }
      }).then((da) => {
        that[entityName] = da.data.result;
        that.$nextTick(()=>{
          document.title = that.company.title;
        })
      });
    },
    setHeadHeight: function () {
      let head = document.getElementById("zone-head").offsetHeight;
      document.getElementById("zone-head-placeholder").style.height = head + "px";
    }
  }, created() {
    this.headCompanyId = this.$route.query.companyId;
    this.headId = this.$route.query.id;
    this.headLogo = this.$route.query.headLogo;
    if (this.$route.query.activeClass != undefined && this.$route.query.activeClass != null && this.$route.query.activeClass != '') {
      this.activeClass = this.$route.query.activeClass;
    }
    this.query.searchName = this.$route.query.searchName;
    let modelId ="";
    if (this.$route.query.id == '2540'){
      modelId = "notice_type_cgzx";
      this.buyerTitle="采购公告";
    }else {
      this.buyerTitle="招采公告";
      modelId = "notice_type";
    }

    /*初始化公告栏目*/
    this.selectNoticeList(modelId, "noticeTypeList", this.activeClass4);
    /*初始化采购专区*/
    this.selectPromotionList("buyer", "buyer", "buyerList");
    this.getCategory(this.$route.query.companyId);
    this.checkUser(this.getCookie("uuid"));
    this.getCompany(this.$route.query.companyId, "company");
    /*初始化卡片*/
    this.getConfigs(203);
  },
  mounted() {
    this.setHeadHeight();
    window.addEventListener('resize', () => {
      this.setHeadHeight();
    }, true)
  },
}
</script>
<style scoped lang="css" src="../../assets/commoon/css/common.css"></style>

<style scoped>
.cms-footer {
  width: 100%;
  height: 40px;
  background: #EFEFEF;
  display: flex;
  justify-content: center;
  color: #666666;
  font-size: 1.4rem;
  align-items: center;
}
.notice-left {
  width: 100% !important;
}
.logo-image {
  width: 400px;
  height: 60px;
  margin: 2px 4px 1px 4px;
}

.cms-header-content {
  background: #FFFFFF;
  width: 100%;
  height: 79px;
  margin: 0 auto;
  padding-top: 5px;
}

@media (min-width: 992px) and (max-width: 1200px) {
  .cms-header-content {
    background: #FFFFFF;
    width: 100%;
    height: 83px;
    margin: 0 auto;
    padding-top: 10px;
  }

  .logo-image {
    width: 334px;
    height: 63px;
    margin: 2px 4px 1px 4px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .cms-header-content {
    background: #FFFFFF;
    width: 100%;
    height: 61px;
    margin: 0 auto;
    padding-top: 10px;
  }

  .logo-image {
    width: 247px;
    height: 46px;
    margin: 2px 4px 1px 4px;
  }
}

@media (min-width: 576px ) and (max-width: 768px) {

  .cms-header-content {
    background: #FFFFFF;
    width: 100%;
    height: 61px;
    margin: 0 auto;
    padding-top: 10px;
  }

  .logo-image {
    width: 247px;
    height: 46px;
    margin: 2px 4px 1px 4px;
  }
}

.qy_tender_a:hover .tender_span {
  color: rgb(0, 104, 183) !important;
}

.qy_tab_con li a:hover {
  background: rgb(0, 104, 183) !important;
  color: #fff;
}


</style>

<style>
.bulletinSelectRadio .el-radio-button__inner {
  background: transparent;
  border: none !important;
  font-size: 1.4rem;
  border-radius: 15px !important;
  padding: 0px 15px;
  line-height: 24px;
}




</style>


<style>
/*分页背景颜色*/
.qy-cms-box .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: rgb(0, 104, 183) !important;
}
</style>